h1.index_pageTitle_18PwE {
  font: normal normal 500 24px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  color: #1F2437;
  margin-bottom: 21px;
}
h2.index_cardTitle_1HbY8, .card > .card-header > h2 {
  padding: 10px 20px;
  font: normal normal 500 18px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  color: #1F2437;
  border-left: 4px solid #5068C2;
}
.ant-btn {
  font: normal normal 500 14px/30px "Noto Sans JP", sans-serif;
}
.ant-btn.index_filledBtn_1Mow8 {
    background: #5068C2 0% 0% no-repeat padding-box !important;
    border-style: none !important;
    border-radius: 4px;
    opacity: 1;
    text-align: center;
    font: normal normal 500 14px/30px "Noto Sans JP", sans-serif !important;
    letter-spacing: 0px;
    color: #FFFFFF !important;
    padding: 0 18px;
}
.ant-btn.index_filledBtn_1Mow8 span {
      color: #FFFFFF !important;
}
.ant-btn.index_filledBtn_1Mow8:hover {
      opacity: 0.2;
}
.ant-btn:not(.ant-btn-primary):not(.ant-btn-danger):not(.index_filledBtn_1Mow8) {
    color: #5068C2 !important;
    background: #fff 0% 0% no-repeat padding-box;
    border: 1px solid #D8D8DA;
    border-radius: 4px;
    opacity: 1;
}
.ant-btn:not(.ant-btn-primary):not(.ant-btn-danger):not(.index_filledBtn_1Mow8):hover {
      background: #EFEFF7 0% 0% no-repeat padding-box;
      border: 1px solid #5068C2 !important;
      border-radius: 4px;
      opacity: 1;
}
.ant-btn:disabled {
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: #f2f4f8 !important;
    box-shadow: none !important;
    pointer-events: none;
}
.ant-popover-buttons button {
  line-height: 24px !important;
}
.ant-pagination-item {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #D8D8DA;
  border-radius: 4px;
}
.ant-pagination-item:hover {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #5068C2;
    border-radius: 4px;
}
.ant-pagination-item-active {
  background: #EFEFF7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  color: #5068C2;
}
.ant-list-pagination {
  text-align: center;
}
.ant-pagination {
  text-align: center !important;
}
.ant-list-item:hover {
  background: #EFEFF7 0% 0% no-repeat padding-box;
}
.ant-list-item .ant-list-item-action-split {
  width: 0;
}
.card > .card-header {
  padding-left: 0 !important;
}
.card .card-body {
  padding: 16px !important;
}
@media screen and (max-width: 767px) {
.card .card-body {
      padding-left: 12px !important;
}
}
.ant-radio-group :not(.ant-radio-checked) span.ant-radio-inner {
  border-color: #BBBBBB !important;
}
.ant-radio-group :not(.ant-radio-checked) span.ant-radio-inner::after {
    border-color: #BBBBBB !important;
}
.ant-radio-group .ant-radio-checked span.ant-radio-inner {
  border-color: #5068C2 !important;
}
.ant-radio-group .ant-radio-checked span.ant-radio-inner::after {
    background-color: #5068C2 !important;
    border-color: #5068C2 !important;
}
.ant-tabs-nav {
  background: #FFF 0% 0% no-repeat padding-box;
  border-radius: 8px 8px 0px 0px;
  color: #3042A0;
  font: normal normal 500 14px/20px "Noto Sans JP", sans-serif;
}
.ant-tabs-nav .ant-tabs-tab:hover {
    color: #3042A0;
    background: #EFEFF7 0% 0% no-repeat padding-box;
    border-radius: 8px 8px 0px 0px;
}
.ant-tabs-nav .ant-tabs-tab-active {
    background: #3042A0 0% 0% no-repeat padding-box;
    border-radius: 8px 8px 0px 0px;
    color: #FFF;
}
.ant-tabs-nav .ant-tabs-tab-active:hover {
      background: #3042A0 0% 0% no-repeat padding-box;
      color: #FFF;
}
.ant-tabs-nav .ant-tabs-ink-bar {
    background-color: #3042A0 !important;
}
.ant-checkbox-group .ant-checkbox-wrapper {
  font: normal normal normal 14px/33px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
}
.ant-checkbox-group .ant-checkbox-wrapper .ant-checkbox {
    border: 1px solid #BBBBBB;
    border-radius: 2px;
}
.ant-checkbox-group .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #5068C2;
    border-color: #5068C2;
}
.ant-form .ant-form-item > .ant-form-item-label > label {
  font: normal normal normal 14px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  color: #787B87;
}
span.index_requiredMark_ZJ1pd {
  display: inline-block;
  width: 36px;
  height: 20px;
  background: #EF5350 0% 0% no-repeat padding-box;
  border-radius: 2px;
  color: #fff;
  font: normal normal normal 11px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  margin: 8px;
  padding: 1px 6px;
}
.ant-table-wrapper table {
  table-layout: fixed;
  word-break: break-all;
  border-collapse: separate;
  border-radius: 4px;
  border: none !important;
}
.ant-table-wrapper table th {
    border: none !important;
}
.ant-table-wrapper tr {
  font: normal normal 300 14px/14px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
}
.ant-table-wrapper tr.index_stripe0_3KZEr {
    background: #FAFBFC 0% 0% no-repeat padding-box;
}
.ant-table-wrapper tr.index_stripe0_3KZEr td.ant-table-column-sort {
      background: #FAFBFC 0% 0% no-repeat padding-box;
}
.ant-table-wrapper tr.index_stripe1_2MimZ {
    background: #FFFFFF 0% 0% no-repeat padding-box;
}
.ant-table-wrapper tr.index_stripe1_2MimZ td.ant-table-column-sort {
      background: #FFFFFF 0% 0% no-repeat padding-box;
}
.ant-table-wrapper tr td {
    border: none;
}
.ant-table-wrapper .ant-table-header-column .ant-table-column-sorter {
  vertical-align: top;
}
.ant-table-wrapper .ant-table-header-column .ant-table-column-sorter .on {
    color: #5068C2 !important;
}
.ant-table-small {
  border: none;
}
.ant-table-small .ant-table-body {
    margin: 0;
}
.ant-table-small .ant-table-body table {
      border-collapse: separate;
      border-radius: 4px;
}
.ant-table-small .ant-table-body table th {
        font: normal normal 500 12px/33px "Noto Sans JP", sans-serif;
        letter-spacing: 0px;
        color: #1F2437;
        background: #EFF0F3 0% 0% no-repeat padding-box !important;
        height: 36px !important;
        padding: 0px 10px !important;
        border: none !important;
}
@media screen and (max-width: 767px) {
.ant-table-small .ant-table-body table th {
            font: normal normal 500 11px/33px "Noto Sans JP", sans-serif;
}
}
.ant-table-small .ant-table-body table td {
        font: normal normal 300 14px/33px "Noto Sans JP", sans-serif;
        letter-spacing: 0px;
        padding: 0px 10px !important;
}
@media screen and (max-width: 767px) {
.ant-table-small .ant-table-body table td {
            font: normal normal 500 11px/33px "Noto Sans JP", sans-serif;
}
}
.ant-table-small .ant-table-body table tr.index_stripe_3r19W {
        background: #FAFBFC 0% 0% no-repeat padding-box;
}
.index_logo_hObJ1 {
  background: #5068C2 0% 0% no-repeat padding-box;
  overflow: hidden;
}
.index_logoContainer_1zCMH {
  padding: 28px 45px;
}
.index_logoContainer_1zCMH .index_logoIcon_1J8Ct {
    width: 32px;
    height: 27px;
    margin: 0 68px 12px;
}
.index_logoContainer_1zCMH .index_logoText_2SGc1 {
    width: 168px;
    height: 19px;
}
.index_logoContainerCollapsed_38BWA {
  padding: 28px 0px 16px 20px;
  margin: 19px 0px;
}
.index_logoContainerCollapsed_38BWA .index_logoIconCollapsed_1LZjw {
    width: 36px;
    height: 36px;
}
.index_icon_GAAV- {
  text-align: center;
  position: absolute;
  left: 22px;
  width: 16px;
  top: 10px;
  margin: 0 !important;
  line-height: 1 !important;
  color: #FFFFFF;
}
.index_navigation_2xL6C {
  background: #5068C2 0% 0% no-repeat padding-box;
}
.index_navigation_2xL6C.index_light_ZdWfm:before, .index_navigation_2xL6C.index_light_ZdWfm:after {
    content: '';
    display: block;
    width: 1px;
    height: 100%;
    background: #b8beca;
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
.index_navigation_2xL6C.index_light_ZdWfm:after {
    left: auto;
    right: 0;
}
.settings__menuShadow .index_navigation_2xL6C.index_light_ZdWfm:before, .settings__menuShadow .index_navigation_2xL6C.index_light_ZdWfm:after {
    display: none;
}
@media (max-width: 1435px) {
.settings__fixedWidth .index_navigation_2xL6C.index_light_ZdWfm:before {
      display: none;
}
}
.index_navigation_2xL6C .ant-menu-root {
    padding: 1.15rem 0 2.3rem !important;
    transition: all 0.3s !important;
    border: none;
}
.index_navigation_2xL6C .ant-menu-root > .ant-menu-item-divider {
      margin: 0 23px 0 10px !important;
      transition: all 0.3s !important;
}
.index_navigation_2xL6C .ant-menu-root.ant-menu-inline-collapsed > .ant-menu-item > a .badge,
    .index_navigation_2xL6C .ant-menu-root.ant-menu-inline-collapsed > .ant-menu-item > span .badge {
      display: none;
}
.index_navigation_2xL6C .ant-menu-inline,
  .index_navigation_2xL6C .ant-menu-vertical,
  .index_navigation_2xL6C .ant-menu-vertical-left {
    border-right: none;
}
.index_navigation_2xL6C .ant-menu-inline-collapsed .index_title_36ENs {
    display: none;
}
.index_navigation_2xL6C .ant-menu-inline-collapsed .ant-menu-submenu > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow {
    opacity: 1;
}
.index_navigation_2xL6C .ant-menu-inline-collapsed .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
    display: block;
    opacity: 0.45;
    top: 48%;
    right: 14px;
}
.index_navigation_2xL6C .ant-menu-inline-collapsed .index_icon_GAAV- {
    right: 30px;
}
.index_navigation_2xL6C .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
    right: 50px;
}
.index_navigation_2xL6C .ant-menu-light .index_icon_GAAV- {
    color: #b8beca;
    transition: all 0.2s ease-in-out;
}
.index_navigation_2xL6C .ant-menu-light .ant-menu-submenu-arrow {
    opacity: 0.7;
}
.index_navigation_2xL6C .ant-menu-light .ant-menu-item:after {
    right: auto;
    left: 0;
}
.index_navigation_2xL6C .ant-menu-light .ant-menu-item:hover .index_icon_GAAV-,
  .index_navigation_2xL6C .ant-menu-light .ant-menu-submenu:hover .index_icon_GAAV- {
    color: #74708d;
}
.index_navigation_2xL6C .ant-menu-light .ant-menu-item,
  .index_navigation_2xL6C .ant-menu-light .ant-menu-submenu-title {
    width: 100%;
}
.index_navigation_2xL6C .ant-menu-dark {
    background: #5068C2 0% 0% no-repeat padding-box;
}
.index_navigation_2xL6C .ant-menu-dark .ant-menu-item-divider {
      opacity: 0.2;
}
.index_navigation_2xL6C .ant-menu-dark .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow {
      opacity: 1;
}
.index_navigation_2xL6C .ant-menu-dark .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before, .index_navigation_2xL6C .ant-menu-dark .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after {
        background: #fff !important;
}
.index_navigation_2xL6C .ant-menu-dark .ant-menu-sub {
      color: #b8beca;
}
.index_navigation_2xL6C .ant-menu-dark .ant-menu-sub.ant-menu-inline {
        box-shadow: none !important;
        transition: all 0.3s;
}
.index_navigation_2xL6C .ant-menu-dark .ant-menu-item {
      height: 36px;
}
.index_navigation_2xL6C .ant-menu-dark .ant-menu-item > a,
      .index_navigation_2xL6C .ant-menu-dark .ant-menu-item span:not(.badge) {
        padding: 0px 12px;
        text-align: left;
        font: normal normal normal 14px/36px "Noto Sans JP", sans-serif;
        letter-spacing: 1.12px;
        color: #FFFFFF;
        opacity: 0.8;
        transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.index_navigation_2xL6C .ant-menu-dark .ant-menu-item:hover > a,
      .index_navigation_2xL6C .ant-menu-dark .ant-menu-item:hover span {
        opacity: 1;
}
.index_navigation_2xL6C .ant-menu-dark .ant-menu-item:hover > a .index_icon_GAAV-,
        .index_navigation_2xL6C .ant-menu-dark .ant-menu-item:hover span .index_icon_GAAV- {
          opacity: 1;
}
.index_navigation_2xL6C .ant-menu-dark .ant-menu-item-selected {
      background: #3042A0 0% 0% no-repeat padding-box;
      border-radius: 0px 24px 24px 0px;
}
.index_navigation_2xL6C .ant-menu-dark .ant-menu-item-selected > a,
      .index_navigation_2xL6C .ant-menu-dark .ant-menu-item-selected span {
        opacity: 1 !important;
}
.index_navigation_2xL6C .ant-menu-dark .ant-menu-item-selected > a .index_icon_GAAV-,
        .index_navigation_2xL6C .ant-menu-dark .ant-menu-item-selected span .index_icon_GAAV- {
          opacity: 1 !important;
}
.index_navigation_2xL6C .ant-menu-dark .ant-menu-submenu:hover .index_icon_GAAV-,
    .index_navigation_2xL6C .ant-menu-dark .ant-menu-submenu-selected .index_icon_GAAV-,
    .index_navigation_2xL6C .ant-menu-dark .ant-menu-submenu-open .index_icon_GAAV- {
      opacity: 1;
      color: #fff !important;
}
.index_navigation_2xL6C .ant-menu-dark .ant-menu-submenu-active .index_icon_GAAV- {
      color: #08f !important;
}
.index_navigation_2xL6C .ant-menu-dark .ant-menu-inline.ant-menu-sub {
      box-shadow: none !important;
      transition: all 0.3s;
}
.index_navigation_2xL6C .ant-menu-dark .ant-menu-item:after {
      right: auto;
      left: 0;
}
.ant-menu-inline-collapsed-tooltip .index_icon_GAAV- {
  display: none;
}
.index_scrollbarDesktop_3UVLL {
  height: calc(100vh - 112px) !important;
}
.index_scrollbarMobile_zkdfa {
  height: calc(100vh - 64px) !important;
}
